import { FolderResource, ResourceMetadata } from '@datavillage-me/api';
import ExternalLinkIcon from 'assets/icons/external-link.svg';
import FileIcon from 'assets/icons/file-text.svg';
import FolderIcon from 'assets/icons/folder.svg';
import Table from 'components/table/Table';
import React, { useMemo } from 'react';
import { TableRow } from '../../../components/table/Table.styles';
import { File, Folder, Icon, Location } from './StoredData.styled';
import StoredDataLoadingState from './StoredData.loading';
import Tooltip from '../../../components/tooltip';
import { useTranslation } from 'react-i18next';

type Props = {
  isPending: boolean;
  folder: FolderResource;
  onResourceSelect: (resourceName: string) => void;
};

const FolderView = ({ folder, onResourceSelect, isPending }: Props) => {
  const { t } = useTranslation(['dataPods']);
  // TODO change sort field by clicking on table header
  const sortField: keyof ResourceMetadata = 'lastModified';

  const sortedItems = useMemo(() => {
    return folder.content.sort((file1, file2) =>
      new String(file1[sortField]).toLowerCase() >= new String(file2[sortField]).toLowerCase() ? 1 : -1
    );
  }, [folder]);

  return (
    <Table
      tableHeaders={[
        { title: 'Name' },
        { title: 'Type' },
        { title: 'Last modified' },
        { title: 'Location', width: 100 },
      ]}
    >
      {isPending ? (
        <StoredDataLoadingState />
      ) : (
        sortedItems.map((resource) => {
          return (
            <TableRow noHover key={resource.name}>
              {resource.type === 'folder' ? (
                <Folder onClick={() => onResourceSelect(resource.name + (resource.name.endsWith('/') ? '' : '/'))}>
                  <Icon className="margin-x" src={FolderIcon} alt="folder" />
                  {resource.name}
                </Folder>
              ) : (
                <File onClick={() => onResourceSelect(resource.name)}>
                  <Icon className="margin-x" src={FileIcon} alt="file" />
                  {resource.name}
                </File>
              )}
              <td>{resource.type != 'folder' ? resource.contentType : null}</td>
              <td>{new Date(resource.lastModified).toLocaleString()}</td>
              {resource.directUrl ? (
                <Tooltip position="left" content={t('dataPodDetails.folderView.tooltip')}>
                  <Location onClick={() => window.open(resource.directUrl, 'NEW')}>
                    <Icon className="margin-x" src={ExternalLinkIcon} alt="external link" />
                  </Location>
                </Tooltip>
              ) : null}
            </TableRow>
          );
        })
      )}
    </Table>
  );
};

export default FolderView;
