import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';
import { PaperHeader, PaperSubHeading, Section } from 'components/paper/Paper';
import React from 'react';
import Explanations from './components/Explanations';
// import RecentActivityTile from './activity/RecentActivityTile';
import { HorizontalFlow, RedirectionContainer } from './Dashboard.styled';
import RecentRecommendations from './recommendations/RecentRecommendations';
import { useTranslation } from 'react-i18next';
import { ApplicationDescriptor, UserScopeApplication } from '@datavillage-me/api';

type Props = {
  isFetchAAPending: boolean;
  activeApplications: ApplicationDescriptor[] | null;
  timeLeft: number;
  cancelRedirect: () => void;
  app?: UserScopeApplication;
};

const Dashboard: React.FC<Props> = (props: Props) => {
  const { isFetchAAPending, activeApplications, app, timeLeft, cancelRedirect } = props;
  const { t } = useTranslation(['dashboard']);
  return (
    <React.Fragment>
      <PaperHeader>{t('overview.heading')}</PaperHeader>
      <PaperSubHeading>{t('overview.subHeading')}</PaperSubHeading>
      <HorizontalFlow>
        <Section>
          <Explanations />
        </Section>
        <Section>
          <RecentRecommendations isAApending={isFetchAAPending} activeApplications={activeApplications} />
        </Section>
      </HorizontalFlow>

      {app && (
        <RedirectionContainer>
          <h2 id="time-left">
            You will be redirect to {app.name} in {(timeLeft / 1000).toFixed(0)} seconds
          </h2>
          <Button
            buttonType={buttonTypes.primary}
            onClick={() => {
              window.open(app.appSettings.appUrl + '?startPassport=true');
            }}
            label={t('overview.redirectNow')}
          />
          <Button
            buttonType={buttonTypes.danger}
            label={t('overview.cancelRedirection')}
            onClick={() => cancelRedirect()}
          />
        </RedirectionContainer>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
