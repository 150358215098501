import React, { useContext, useEffect, useState } from 'react';
import { DATA_SOURCE_TYPE, DataSourceMetadata, MASTER_POD_ALIAS } from '@datavillage-me/api';
import { useTranslation } from 'react-i18next';
import { ProcessNavigation } from '@datavillage-me/dv-common-ui';

import LogoImg from '/src/assets/images/Wallet_Logo_Dark.png';
import '../../../config/i18n';
import styled from '../../../config/theme/styled';
import { PassportHome } from '../../passport-home/PassportHome';
import { PassportSelectSources } from '../../passport-select-sources/PassportSelectSources';
import { PassportSignConsent } from '../../passport-sign-consent/PassportSignConsent';
import { AppContext, useRemoteClient } from '../../app/components/AppContext';
import { usePassportQueryParams } from '../../../hooks/usePassportQueryParams';
import PassportErrorBoundary from './PassportErrorBoundary';
import { PassportAppContext, SharedDataSource, Status } from '../context/PassportContext';
import { Loader } from '../../../components/loader/Loader';
import {
  extractActionsFromMapping,
  filterProvidersFromConsentReceipt,
  isDataSourceActivated,
} from '../../../utils/dataSources';
import { palette } from '../../../config/theme/themeFiles';
import { useQuery } from '../../../utils';
import { extractDataSourceIds } from '../../../utils/consents';

const PassportApp = () => {
  return (
    <PassportAppContext.StatefulProvider initContext={() => ({ sharedDataSources: [] })}>
      <PassportErrorBoundary>
        <PassportAppInContext />
      </PassportErrorBoundary>
    </PassportAppContext.StatefulProvider>
  );
};

const PassportAppInContext: React.FC = () => {
  const [error, setError] = useState(null);
  if (error) {
    throw error;
  }

  usePassportQueryParams();
  const { t } = useTranslation();
  const uQ = useQuery();

  const appContext = useContext(AppContext),
    passportContext = useContext(PassportAppContext),
    previousConsent = passportContext.previousConsent?.value,
    activatedDataSources = passportContext.activatedDataSources?.value,
    dataSources = passportContext.dataSources?.value,
    remoteClient = useRemoteClient(appContext),
    isUserLoggedIn = !!appContext.currentUser?.id;

  const isLoading =
    passportContext.previousConsent?.status !== Status.SUCCESS ||
    passportContext.application?.status !== Status.SUCCESS;

  const activateDataProviders = (dataSources: DataSourceMetadata[]) => {
    const dataSourcesShared = dataSources
      .map((dataSource) => {
        const isDataSourceAlreadyShared = !!(passportContext.sharedDataSources || []).find(
          (sDS) => sDS.dataSourceId === dataSource.id
        );

        return isDataSourceAlreadyShared
          ? undefined
          : {
              dataSourceId: dataSource.id,
              categories: extractActionsFromMapping(dataSource.mappings),
            };
      })
      .filter(Boolean) as SharedDataSource[];
    passportContext.updateCtx({
      sharedDataSources: [...(passportContext.sharedDataSources || []), ...dataSourcesShared],
    });
  };

  useEffect(() => {
    document.getElementsByClassName('tf-v1-sidetab')[0]?.remove();
  }, []);

  useEffect(() => {
    if (passportContext.passportConfig?.clientId && passportContext.passportConfig?.applicationId) {
      passportContext.updateCtx({ previousConsent: { status: Status.LOADING } });
      if (isUserLoggedIn) {
        Promise.all([
          remoteClient
            .getConsentsServices()
            .getConsents(
              appContext.currentUser!.id,
              passportContext.passportConfig!.clientId,
              passportContext.passportConfig!.applicationId,
              undefined,
              true
            ),
          remoteClient
            .getUsersServices()
            .getPodInstance(appContext.currentUser!.id, MASTER_POD_ALIAS)
            .getActivatedDatasources(),
        ]).then(([[consent], activatedDataSources]) => {
          passportContext.updateCtx({
            previousConsent: { value: consent, status: Status.SUCCESS },
            activatedDataSources: { value: activatedDataSources, status: Status.SUCCESS },
          });
        });
      } else {
        passportContext.updateCtx({ previousConsent: { status: Status.SUCCESS } });
      }
    }
  }, [
    isUserLoggedIn,
    remoteClient,
    passportContext.passportConfig?.clientId,
    passportContext.passportConfig?.applicationId,
  ]);

  useEffect(() => {
    if (passportContext.passportConfig?.clientId && passportContext.passportConfig?.applicationId) {
      passportContext.updateCtx({ application: { status: Status.LOADING } });
      remoteClient
        .getCollaborationSpacesServices()
        .getAllApplications()
        .then((applications) => {
          const app = applications.find((app) => app.id == passportContext.passportConfig!.applicationId);
          passportContext.updateCtx({ application: { value: app, status: Status.SUCCESS } });
        })
        .catch(setError);
    }
  }, [passportContext.passportConfig]);

  // Loading data for next steps if user is authenticated
  useEffect(() => {
    if (
      appContext.currentUser?.id &&
      passportContext.passportConfig?.clientId &&
      passportContext.passportConfig?.applicationId
    ) {
      passportContext.updateCtx({
        dataSources: { status: Status.LOADING },
        activatedDataSources: { status: Status.LOADING },
        consentReceipt: { status: Status.LOADING },
      });
      Promise.all([
        remoteClient.getDataBridge().getDataSources(),
        remoteClient
          .getConsentReceiptsServices()
          .getCurrentConsentReceiptForApplication(
            passportContext.passportConfig.clientId,
            passportContext.passportConfig.applicationId
          ),
      ])
        .then(([dataSources, consentReceipt]) => {
          if (!consentReceipt) throw 'Consent receipt not found';

          consentReceipt['gConsent:forPersonalData'].forEach((fpd) => {
            if (Array.isArray(fpd['dvConsent:personalDataType']))
              fpd['dvConsent:personalDataType'] = fpd['dvConsent:personalDataType'][0];
          });

          const filteredDataSources = filterProvidersFromConsentReceipt(dataSources, consentReceipt);
          activatedDataSources && filteredDataSources.sort((p) => (p.recommended ? -1 : 1));
          passportContext.updateCtx({
            dataSources: { value: filteredDataSources, status: Status.SUCCESS },
            consentReceipt: { value: consentReceipt, status: Status.SUCCESS },
          });
        })
        .catch(setError);
    }
  }, [
    isUserLoggedIn,
    remoteClient,
    passportContext.passportConfig?.clientId,
    passportContext.passportConfig?.applicationId,
  ]);

  useEffect(() => {
    if (dataSources && previousConsent) {
      const dataSourcesToSelect: DataSourceMetadata[] = [];
      extractDataSourceIds(previousConsent).forEach((id) => {
        const dataSource = dataSources.find((dS) => dS.id === id);
        if (dataSource && dataSource.type === DATA_SOURCE_TYPE.PULL) {
          activatedDataSources &&
            isDataSourceActivated(dataSource, activatedDataSources) &&
            dataSourcesToSelect.push(dataSource);
        } else if (dataSource) {
          dataSourcesToSelect.push(dataSource);
        }
      });
      activateDataProviders(dataSourcesToSelect);
    }
  }, [dataSources, previousConsent, activatedDataSources]);

  const steps = [
    ...(uQ.loginSuccess !== 'true' &&
    passportContext.previousConsent?.status === Status.SUCCESS &&
    passportContext.previousConsent?.value
      ? []
      : [
          {
            id: 'firstStep',
            Comp: ComponentWrapper(PassportHome),
          },
        ]),
    { id: 'secondStep', Comp: ComponentWrapper(PassportSelectSources) },
    { id: 'thirdStep', Comp: ComponentWrapper(PassportSignConsent) },
  ];

  return isLoading ? (
    <Loader />
  ) : (
    <PassportAppContainer className={'container'}>
      <HeaderContainer>
        <DatavillageContainer>
          <Logo src={LogoImg} alt="logo" />
        </DatavillageContainer>
        <h3 className={'header-body'}>
          <>
            {t('passport.welcome')}
            <span className={'app-name'}>{passportContext.application!.value!.name}</span>
          </>
        </h3>
      </HeaderContainer>
      <ProcessNavigation
        {...{
          className: 'processNavigation',
          initialStep: uQ.loginSuccess === 'true' ? 1 : 0,
          steps,
        }}
      />
    </PassportAppContainer>
  );
};

const ComponentWrapper = (Comp: React.FC) => {
  return (props: any) => {
    return (
      <CompWrapper>
        <Comp {...props} />
      </CompWrapper>
    );
  };
};

const PassportAppContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 9px;

  .processNavigation {
    height: 75%;
    position: relative;
    top: -15px;
  }
`;

const DatavillageContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;

  .datavillage {
    margin: 0;
    color: ${palette.primary.shade4};
  }
`;

const CompWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 25px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  padding: 25px;
  height: 25%;
  background-color: ${palette.primary.shade1};

  .app-name {
    font-weight: 700;
  }

  .header-body {
    text-align: center;
    margin: 10px 0;
  }
`;

const Logo = styled.img`
  margin-right: 20px;
  width: 240px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  width: calc(100%);
  margin-top: 20px;
  @media (max-width: 750px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export default PassportApp;
