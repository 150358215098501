import { Login, LoginCallback } from 'modules/login';
import SelectProvider from 'modules/login/external-provider/SelectProvider';
import * as React from 'react';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import { useQuery } from '../../../utils';
import PublicRoutes from '../config/PublicRoutes';
import PrivateRoutes from '../config/PrivateRoutes';
import PrivateRoute from '../routes/PrivateRoute';
import ProviderSuccess from 'modules/login/external-provider/ProviderSuccess';

const MainRouter: React.FC = () => {
  const callbackTarget = useQuery().target;

  return (
    <Routes>
      {/* <Route exact path="/register" component={Register} /> */}
      <Route path="/login" element={<Login />} />
      {/* This route should be used as a callback when the login flow is triggered from an external window/extension .
          The 'target' query parameter can be 'opener' (default) or 'extension:<extensionId>' .
          If using the opener, it assumes the window.opener is the window waiting for a notification (see handleExternalAuth)
          If targeting an extension, the target value must contain the ID of the extension to notify
      */}
      <Route path="/login/callback" element={<LoginCallback callbackTarget={callbackTarget} />} />

      <Route path="/provider/:providerId/success" element={<ProviderSuccess />} />

      {
        // TODO: figure out passport and redirectUri arguments
      }
      <Route path="/login/external" element={<SelectProvider passport={true} redirectUri="/dashboard" />} />

      {/* Map out public routes based on config (except SelectProviders) */}
      {PublicRoutes.map((route, i) => (
        <Route path={route.path} key={i} element={<route.component />} />
      ))}

      {PrivateRoutes.map((route, i) => (
        <Route
          path={route.path}
          element={
            <PrivateRoute key={i} path={route.path}>
              <route.component />
            </PrivateRoute>
          }
        />
      ))}
    </Routes>
  );
};

export default MainRouter;
