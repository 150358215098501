import { getRemoteClient, POD_IDENTITY_PROVIDER, POD_IDENTITY_PROVIDERS } from '@datavillage-me/api';
import LogoImg from 'assets/images/Codename_Logo.svg';
import GoogleImg from 'assets/images/google.svg';
import InruptImg from 'assets/images/inrupt.png';
import InruptPodSpaceImg from 'assets/images/inrupt_pod_space.png';
import SolidImg from 'assets/images/solid.svg';
import BackButton from 'components/nav-links/BackButton';
import LoadingOverlay from 'components/overlay/LoadingOverlay';
import styled from 'config/theme/styled';
import paletteTheme from 'config/theme/themeFiles/palette';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { inIframe, useQuery } from 'utils';
import { AppContext } from '../../app/components/AppContext';
import { AppContextType } from '../../app/type';

export async function handleExternalAuth(
  appContext: AppContextType,
  redirectUrl: string,
  apiUrl: string,
  podType: POD_IDENTITY_PROVIDER,
  issuer?: string
) {
  try {
    if (!redirectUrl) {
      // if no redirectUrl is provided, redirect to the app root by removing the hash router path
      const currentUrl = new URL(window.location.href);
      currentUrl.hash = '';
      redirectUrl = encodeURIComponent(currentUrl.toString());
    }

    // TODO use separate login window always, or only when embedded in iFrame ?
    if (inIframe()) {
      // postMessage listening function
      const listener = (e: MessageEvent) => {
        if (e.origin != window.location.origin) {
          console.warn(`Received a login callback from wrong origin (${e.origin}) ; ignoring `);
        } else {
          try {
            if (e.data == 'OK') {
              // the login has succeeded - let's redirect to the original redirectUrl ...
              window.location.href = redirectUrl;
            } else {
              // TODO process error status
              console.warn('Unknown login flow event : ' + e.data);
            }
          } finally {
            // remove the postMessage listener
            window.removeEventListener('message', listener, false);
            loginWindow && loginWindow.close();
          }
        }
      };

      // use /login/callback as redirectUrl. That will result in a postMessage upon login
      // the actual redirectUrl will then be used in the listener above
      const authUrl = await getRemoteClient(apiUrl)
        .getPassport()
        .getAuthenticationUrl(window.location.origin + '/login/callback', podType, issuer);

      window.addEventListener('message', listener, false);
      const loginWindow = window.open(authUrl);
    } else {
      // Build the external auth URL that will target the Passport Auth component
      // The successful login flow will eventually result in a redirect from Passport to the redirectUrl
      const authUrl = await getRemoteClient(apiUrl).getPassport().getAuthenticationUrl(redirectUrl, podType, issuer);

      window.location.href = authUrl;
    }
  } catch (error) {
    toast.error(error);
  }
}

type Props = { passport: boolean };
type SelectProviderProps = Props & { redirectUri: string };
const SelectProvider = ({ passport, redirectUri }: SelectProviderProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const appContext = useContext(AppContext);
  const redirectUrlParam = useQuery().redirectUrl;

  const handleGoogleExternalAuth = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    handleExternalAuth(
      appContext,
      redirectUrlParam || redirectUri,
      appContext.passport_url,
      POD_IDENTITY_PROVIDERS.Google
    );
  };

  const handleSolidWebExternalAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    handleExternalAuth(
      appContext,
      redirectUrlParam || redirectUri,
      appContext.passport_url,
      POD_IDENTITY_PROVIDERS.Solid,
      'https://solidweb.org/'
    );

    setLoading(false);
  };

  const handleSolidCommunityExternalAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    handleExternalAuth(
      appContext,
      redirectUrlParam || redirectUri,
      appContext.passport_url,
      POD_IDENTITY_PROVIDERS.Solid,
      'https://solidcommunity.net/'
    );

    setLoading(false);
  };

  const handleInruptPodSpaceExternalAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    handleExternalAuth(
      appContext,
      redirectUrlParam || redirectUri,
      appContext.passport_url,
      POD_IDENTITY_PROVIDERS.Solid,
      'https://broker.pod.inrupt.com/'
    );

    setLoading(false);
  };

  const handleInruptNetExternalAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    handleExternalAuth(
      appContext,
      redirectUrlParam || redirectUri,
      appContext.passport_url,
      POD_IDENTITY_PROVIDERS.Solid,
      'https://inrupt.net/'
    );

    setLoading(false);
  };

  return (
    <LoginWrapper passport>
      {!passport && <Logo src={LogoImg} alt="logo" />}
      <LoginContainer>
        {!passport && <BackButton />}
        <Paragraph>{t('login.external.selectPlatform')}</Paragraph>
        <PlatformButton onClick={handleGoogleExternalAuth}>
          <BrandImg src={GoogleImg} alt={t('login.external.google')}></BrandImg>
          <span>{t('login.external.google')}</span>
        </PlatformButton>
        <PlatformButton onClick={handleSolidWebExternalAuth}>
          <BrandImg src={SolidImg} alt={t('login.external.solidWeb')}></BrandImg>
          <span>{t('login.external.solidWeb')}</span>
        </PlatformButton>
        <PlatformButton onClick={handleSolidCommunityExternalAuth}>
          <BrandImg src={SolidImg} alt={t('login.external.solidCommunity')}></BrandImg>
          <span>{t('login.external.solidCommunity')}</span>
        </PlatformButton>
        <PlatformButton onClick={handleInruptPodSpaceExternalAuth}>
          <BrandImg src={InruptPodSpaceImg} alt={t('login.external.inruptPodSpace')}></BrandImg>
          <span>{t('login.external.inruptPodSpace')}</span>
        </PlatformButton>
        <PlatformButton onClick={handleInruptNetExternalAuth}>
          <BrandImg src={InruptImg} alt={t('login.external.inruptNet')}></BrandImg>
          <span>{t('login.external.inruptNet')}</span>
        </PlatformButton>
      </LoginContainer>
      {loading && <LoadingOverlay />}
      <PolicyWrapper>
        <NavLinkItem>
          <NavLink to="/privacy/terms-of-use">{t('login.termsOfUse')}</NavLink>
        </NavLinkItem>
        <NavLinkItem>
          <NavLink to="/privacy/policy">{t('login.policy')}</NavLink>
        </NavLinkItem>
      </PolicyWrapper>
    </LoginWrapper>
  );
};

export default SelectProvider;

const LoginWrapper = styled.div<Props>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${(props) => (props.passport ? '0px' : '50px')};
  width: ${(props) => props.passport && '100%'};
  max-width: 100%;
  padding: 16px;
`;

const Logo = styled.img`
  margin-bottom: 50px;
  width: 250px;
`;

const LoginContainer = styled.div`
  border-radius: 15px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  padding: 30px 20px;
  width: 400px;
`;

const Paragraph = styled.p`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 50px;
`;

const PlatformButton = styled.button`
  background: none;
  border-radius: 10px;
  border: 1px solid ${paletteTheme.neutral.shade2};
  color: ${paletteTheme.neutral.shade7};
  cursor: pointer;
  display: block;
  font-weight: 400;
  margin-bottom: 20px;
  padding: 5px 16px;
  text-align: center;
  width: 100%;

  &:hover {
    color: ${paletteTheme.neutral.shade9};
    border: 1px solid ${paletteTheme.neutral.shade5};
  }

  span {
    margin-left: 10px;
  }
`;

const BrandImg = styled.img`
  height: 35px;
  vertical-align: middle;
`;

const PolicyWrapper = styled.div`
  align-items: center;
  display: flex;
  font-family: 'Poppins', sans-serif;
  margin-top: -80px;
`;

const NavLinkItem = styled.div`
  margin: 120px;
  text-align: center;
`;
