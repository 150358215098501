import React from 'react';
import { useParams } from 'react-router-dom';

const ProviderSuccess: React.FC = () => {
  const urlParams = useParams<{ providerId: string }>();
  if (!urlParams.providerId) {
    // TODO: how to handle empty providerId?
    return <></>;
  }

  localStorage.setItem('providerActivationSuccess', urlParams.providerId);
  window.open('about:blank', '_self');
  window.close();

  return <></>;
};

export default ProviderSuccess;
