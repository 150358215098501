import { FileResource, FolderResource, RESOURCE_TYPE } from '@datavillage-me/api';
import HomeIcon from 'assets/icons/home.svg';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CodeEditor, guessMode } from '../../../utils/code-editor';
import { PromiseContainer } from '@datavillage-me/dv-common-ui';
import useStoredDataStore from '../hooks/useStoredDataStore';
import FolderView from './FolderView';
import StoredDataLoadingState from './StoredData.loading';
import { Breadcrumb, BreadcrumbWrapper, Divider, Icon, IconWrapper } from './StoredData.styled';

type Props = {
  pod: string;
  path?: string;
};

const StoredData = ({ pod, path }: Props) => {
  const currentPath = path || '';
  const navigate = useNavigate();
  //const location = useLocation();

  const { podResource, fetchDataPodResources, isPending } = useStoredDataStore();

  const getPodResource = useCallback(() => {
    fetchDataPodResources(pod, currentPath);
  }, [currentPath, fetchDataPodResources, pod]);

  useEffect(() => {
    getPodResource();
  }, [getPodResource]);

  const breadcrumbs = useMemo<[string, string][]>(() => {
    // compute the breadcrumbs as an array of [breadcrumbName, path] pairs, so that
    // '/a/b/c'   will become   [['a', '/a'], ['b', '/a/b'], ['c', '/a/b/c]]
    const pathElements = currentPath.split('/').filter((val) => val !== '');

    return pathElements.map((value, index) => [value, pathElements.slice(0, index + 1).join('/')]);
  }, [currentPath]);

  const updateCurrentPath = (path: string) => {
    // TODO use sanitizePath from @datavillage-me/util
    if (!path.startsWith('/')) path = '/' + path;

    //setCurrentPath(path);
    navigate('/myData' + path);
  };

  return (
    <div>
      <BreadcrumbWrapper>
        <IconWrapper onClick={() => updateCurrentPath('/')}>
          <Icon src={HomeIcon} className="margin-x" alt="home" />
          {'Home folder'}
        </IconWrapper>
        {breadcrumbs.map((breadcrumb, i) => (
          <React.Fragment key={breadcrumb[1]}>
            <Divider>{'>'}</Divider>
            <Breadcrumb onClick={() => updateCurrentPath(breadcrumb[1] + '/')}>{breadcrumb[0]}</Breadcrumb>
          </React.Fragment>
        ))}
      </BreadcrumbWrapper>
      {isPending ? (
        <StoredDataLoadingState topSpacing />
      ) : podResource?.metadata.type == RESOURCE_TYPE.Folder ? (
        <FolderView
          isPending={isPending}
          folder={podResource as FolderResource}
          onResourceSelect={(name) =>
            updateCurrentPath(currentPath + (currentPath.endsWith('/') || name.startsWith('/') ? '' : '/') + name)
          }
        />
      ) : (
        podResource && <FileView file={podResource as FileResource} />
      )}
    </div>
  );
};

const FileView = ({ file }: { file: FileResource }) => {
  console.log('!!! content type', file.metadata.contentType);
  return (
    <PromiseContainer promise={file.content.text()}>
      {(value) => (
        <CodeEditor
          code={value}
          language={guessMode(file.metadata.name, file.metadata.contentType)}
          onCodeChange={(value) => {
            console.log(value);
          }}
        />
      )}
    </PromiseContainer>
  );
};

export default StoredData;
