import CheckmarkIcon from 'assets/icons/check_dark_circle.svg';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { Button, buttonTypes, Link } from '@datavillage-me/dv-common-ui';
import { useTranslation } from 'react-i18next';
import { PassportAppContext } from '../passport-app/context/PassportContext';
import { AppContext } from '../app/components/AppContext';
import { replaceAppNameInLabel } from '../../utils/labels';
import useToggle from '../../hooks/useToggle';
import { Footer } from '../passport-app/containers/PassportApp';
import { Login } from '../login';

type Props = { goToNextStep: VoidFunction; validateStep: VoidFunction };
export const PassportHome: React.FC<Props> = ({ goToNextStep, validateStep }) => {
  // TODO accept a redirectUrl to be redirected to after passport flow
  //      use that (with chrome.runtime.sendMessage in the case of extension popup) to notify extension of consent result
  const appContext = useContext(AppContext);
  const passportContext = useContext(PassportAppContext);
  const [showLogin, toggleLogin] = useToggle(false);
  const { t } = useTranslation();

  const isUserLoggedIn = !!appContext.currentUser?.id;

  useEffect(() => {
    validateStep();
  }, []);

  return (
    <Container>
      {showLogin ? (
        <Login passport redirectUri={window.location.toString() + '&loginSuccess=true'} />
      ) : (
        <>
          <Body>
            <p>{replaceAppNameInLabel(t, 'passport.home.body', passportContext)}</p>
            <ChecksWrapper>
              <CheckMark>
                <img src={CheckmarkIcon} alt="checkmark" />
                <p>{replaceAppNameInLabel(t, 'passport.home.bullet1', passportContext)}</p>
              </CheckMark>
              <CheckMark>
                <img src={CheckmarkIcon} alt="checkmark" />
                <p>{replaceAppNameInLabel(t, 'passport.home.bullet2', passportContext)}</p>
              </CheckMark>
              <CheckMark>
                <img src={CheckmarkIcon} alt="checkmark" />
                <p>{replaceAppNameInLabel(t, 'passport.home.bullet3', passportContext)}</p>
              </CheckMark>
            </ChecksWrapper>
            <Link className="learnMore" href="/" target="_blank">
              Learn more about your data wallet
            </Link>
          </Body>
          <Footer>
            <Button
              {...{
                buttonType: buttonTypes.secondary,
                onClick: () =>
                  window.parent.postMessage(
                    {
                      source: 'codename',
                      content: 'closeModal',
                    },
                    '*'
                  ),
                label: t('general.cancel'),
              }}
            />
            <Button
              {...{
                buttonType: buttonTypes.primary,
                onClick: isUserLoggedIn ? goToNextStep : toggleLogin,
                label: t(isUserLoggedIn ? 'general.continue' : 'login.loginOrRegister'),
              }}
            />
          </Footer>
        </>
      )}
    </Container>
  );
};

const Body = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const ChecksWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const CheckMark = styled.span`
  display: flex;
  margin-bottom: 15px;

  > img {
    margin-right: 10px;
    width: 24px;
  }
`;
