import React, { useContext, useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import useDashboardStore from './hooks/useDashboardStore';
import { AppContext, useRemoteClient } from 'modules/app/components/AppContext';
import useCountDown from 'react-countdown-hook';
import { UserScopeApplication } from '@datavillage-me/api';
import { fetchApplications } from 'modules/app/components/actions';

const DashboardPage: React.FC = () => {
  const {
    activeApplications,
    fetchActivatedDataSources,
    fetchActiveApplications,
    fetchActivityLog,
    fetchConsents,
    // activityLog,
    // isActivityLogPending,
    isFetchAAPending,
  } = useDashboardStore();

  const getDashboardData = React.useCallback(() => {
    fetchActivatedDataSources();
    fetchActiveApplications();
    fetchActivityLog();
    fetchConsents();
  }, [fetchActiveApplications, fetchActivatedDataSources, fetchConsents, fetchActivityLog]);

  React.useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  const context = useContext(AppContext);
  const client = useRemoteClient();

  const [timeLeft, actions] = useCountDown(3000, 100);
  const [appId, setAppId] = useState<string>();
  const [app, setApp] = useState<UserScopeApplication>();

  useEffect(() => {
    const localStorageAppId = localStorage.getItem('appId');
    localStorage.removeItem('appId');
    localStorageAppId && setAppId(localStorageAppId);
  }, []);

  useEffect(() => {
    if (!context.applications.loading && !context.applications.error && !context.applications.data) {
      fetchApplications(context, client);
    }

    if (context.applications.data && appId) {
      const matchingApp = context.applications.data.find((app) => app.id === appId);
      if (matchingApp) {
        setApp(matchingApp);
        actions.start();
      }
    }
  }, [context.applications]);

  useEffect(() => {
    if (timeLeft === 0 && app) {
      window.open(app.appSettings.appUrl + '?startPassport=true');
      setAppId(undefined);
      setApp(undefined);
    }
  }, [timeLeft]);
  return (
    <Dashboard
      isFetchAAPending={isFetchAAPending}
      activeApplications={activeApplications}
      timeLeft={timeLeft}
      cancelRedirect={() => {
        setApp(undefined);
        actions.reset();
      }}
      app={app}
    />
  );
};

export default DashboardPage;
