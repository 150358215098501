import {
  DataSourceMappingMetadata,
  DataSourceMetadata,
  DatasourceRecord,
  reduceWithWellKnownPrefix,
  prependNamespacePrefix,
} from '@datavillage-me/api';
import { TFunction } from 'i18next';
import { ConsentReceipt } from '@datavillage-me/api';
import SpotifyIcon from '../assets/images/spotify.svg';
import FacebookIcon from '../assets/images/facebook.svg';
import VrtIcon from '../assets/images/vrt.svg';
import AuvioIcon from '../assets/images/auvio.png';
import FileTextIcon from '../assets/icons/file-text.svg';

export const isDataSourceActivated = (
  dataSource: DataSourceMetadata,
  activatedDataSources: DatasourceRecord[]
): boolean => {
  const activatedDataSource = activatedDataSources.find((aDS) => aDS.resourceUri === dataSource.id);

  return (
    !!activatedDataSource &&
    (dataSource.scope || []).every((s) => (activatedDataSource.grantedScopes || []).includes(s))
  );
};

export const extractActionsFromMapping = (mappings: DataSourceMappingMetadata[]) => {
  return mappings.reduce((actions: string[], mapping: DataSourceMappingMetadata) => {
    mapping.categories.forEach((category) => {
      !actions.includes(category) && actions.push(category);
    });

    return actions;
  }, []);
};

export const getActionLabel = (action: string, t: TFunction): string => {
  const actionKey = action.split('/').slice(-1)[0];
  const labelKey = actionKey.charAt(0).toLowerCase() + actionKey.slice(1);

  return t(`passport.providers.actions.${labelKey}`);
};

export const filterProvidersFromConsentReceipt = (
  dataSources: DataSourceMetadata[],
  consentReceipt: ConsentReceipt
): (DataSourceMetadata & { recommended?: boolean })[] => {
  const forPersonalData = consentReceipt['gConsent:forPersonalData'];
  const filteredProviders: (DataSourceMetadata & { recommended?: boolean })[] = [];

  dataSources.forEach((dataSource) => {
    if (dataSource.applicationId !== consentReceipt.applicationId) {
      dataSource.mappings.forEach((mapping) => {
        const fPD = forPersonalData.find(
          (fPD) => fPD['dvConsent:personalDataType'] === reduceWithWellKnownPrefix(mapping.categories[0])
        );
        const dataSourceIndex = filteredProviders.findIndex((fP) => fP.id === dataSource.id);
        if (fPD) {
          if (dataSourceIndex > -1) {
            filteredProviders[dataSourceIndex] = {
              ...dataSource,
              recommended: fPD['dvConsent:personalDataSource'].includes(prependNamespacePrefix('dv', dataSource.id)),
              mappings: [
                ...((filteredProviders[dataSourceIndex] && filteredProviders[dataSourceIndex].mappings) || []),
                mapping,
              ],
            };
          } else {
            filteredProviders.push({
              ...dataSource,
              recommended: fPD['dvConsent:personalDataSource'].includes(prependNamespacePrefix('dv', dataSource.id)),
              mappings: [mapping],
            });
          }
        }
      });
    }
  });

  return filteredProviders;
};

export const getIcon = (brand?: string) => {
  switch (brand?.toLowerCase()) {
    case 'spotify':
      return SpotifyIcon;
    case 'facebook':
      return FacebookIcon;
    case 'auvio':
      return AuvioIcon;
    case 'vrt':
      return VrtIcon;
    default:
      return FileTextIcon;
  }
};
