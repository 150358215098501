import React from 'react';
import { DataPodDetails } from 'modules/data-pod-details';
import { useLocation } from 'react-router-dom';

const MyData: React.FC = () => {
  // trailing slash is not captured in path params from react router
  const resourcePath = () => {
    return useLocation().pathname.split('/myData').slice(-1)[0];
  };

  return (
    <>
      <DataPodDetails path={resourcePath()} />
    </>
  );
};

export default MyData;
