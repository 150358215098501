import { PodDescriptor, getMasterPod } from '@datavillage-me/api';
import * as React from 'react';
import { useContext } from 'react';
import { AppContext, useRemoteClient } from '../app/components/AppContext';
import { PromiseContainer } from '@datavillage-me/dv-common-ui';
import { CodeEditor } from 'utils/code-editor';

/*
 * This page is meant to hold debugging info and dev experimentation with the API
 */

export const Playground = () => {
  const context = useContext(AppContext);

  return (
    <div style={{ position: 'relative', top: '0px', bottom: '0px' }}>
      <div>Passport URL : {context.passport_url}</div>
      <div>
        <h2>Current User</h2>
        <pre>{JSON.stringify(context.currentUser, undefined, 4)}</pre>
      </div>
      <CodeEditor
        code={`
@prefix : </#>.
@prefix mo: <http://purl.org/ontology/mo/>.
@prefix schema: <https://schema.org/>.
@prefix artist: <https://open.spotify.com/artist/>.
@prefix n0: <https://api.spotify.com/v1/me/top/artists/>.
@prefix www: <https://www.spotify.com/>.

# some comments

n0:023YMawCG3OvACmRjWxLWC
    a schema:Action;
    schema:additionalType schema:LikeAction;
    schema:object artist:023YMawCG3OvACmRjWxLWC .
artist:023YMawCG3OvACmRjWxLWC
    a schema:MusicGroup;
    schema:additionalType mo:MusicArtist;
    schema:genre "australian reggae fusion,australian ska,ska jazz";
    schema:name "The Cat Empire".
        `}
        language="turtle"
      />
      {context.currentUser ? (
        <>
          <Pods userId={context.currentUser!.id} />
          <PodContent userId={context.currentUser!.id} pod={getMasterPod(context.currentUser!)} />
        </>
      ) : null}
      {/*
      <div>
        Users: <pre>{JSON.stringify(context.currentUser, undefined, 4)}</pre>
      </div>
      */}
    </div>
  );
};

const Pods = (props: { userId: string }) => {
  const pods = useRemoteClient().getUsersServices().getPods(props.userId);

  return (
    <div>
      <h2>Pods</h2>
      <PromiseContainer promise={pods}>
        {(pods: any) => (
          <>
            {pods.map((pod: any) => (
              <div key={pod.uri}>
                {pod.uri} - {pod.registrationDate}
              </div>
            ))}
          </>
        )}
      </PromiseContainer>
    </div>
  );
};

const PodContent = (props: { userId: string; pod: PodDescriptor }) => {
  const { userId, pod } = props;

  const client = useRemoteClient().getUsersServices();
  const rootFolder = client.getPodInstance(userId, pod.uri).getFolder('/');

  return (
    <div>
      <h2>Pod {pod.uri} - Content</h2>
      <i>Identified as {pod.identity.id}</i>
      <PromiseContainer promise={rootFolder}>
        {(folder: any) => (
          <>
            {folder.content.map((child: any) => (
              <div key={child.name}>
                {child.name} - {child.lastModified}
              </div>
            ))}
          </>
        )}
      </PromiseContainer>
    </div>
  );
};
