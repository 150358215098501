import { getRemoteClient } from '@datavillage-me/api';
import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { Loader } from '../../../components/loader/Loader';
import useListenExtensionVersion from '../../../hooks/useListenExtensionVersion';
import { getExtension } from '../../../utils/extension';
import { createUpdatableContext, ElementsOrFn, UpdatableContextType } from '../../../utils/utils';
import useContextStore from '../hooks/useContextStore';
import { AppContextType } from '../type';
import { fetchApplications } from './actions';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const getApiUrl = () => {
  if (process.env.NODE_ENV !== 'production') {
    return process.env.MIDDLEWARE_URL || 'http://localhost:3050';
  }
  return '__MIDDLEWARE_URL__';
};


export const AppContext = createUpdatableContext<AppContextType>(
  { passport_url: getApiUrl(), applications: { loading: false } },
  (oldValue, update, newValue) => {
    if (oldValue.currentUser?.id != newValue.currentUser?.id) {
      if (newValue.currentUser?.id) {
        newValue.extensionId && getExtension(newValue.extensionId).notifyLoginSuccess();
      } else {
        newValue.extensionId && getExtension(newValue.extensionId).notifyLogoutSuccess();
      }
    }
  }
);

export function getBrowserRemoteClient(url: string) {
  return getRemoteClient(url, undefined, 'BrowserCredentials');
}

/**
 * Return a remote client based on the config in the current or provided AppContext
 * @param ctx
 */
export const useRemoteClient = (ctx?: AppContextType) => {
  const thisAppContext = useContext(AppContext);
  const validCtx = ctx || thisAppContext;

  const client = useMemo(() => {
    return getBrowserRemoteClient(validCtx.passport_url);
  }, [validCtx.passport_url]);

  return client;
};


/**
 * Returns the authenticated user from the AppContext.
 * If no user is authenticated, calls toast and throws an exception
 * @param ctx
 */
export const useAuthenticatedUser = (ctx?: AppContextType) => {
  const { t } = useTranslation(["errors"]);
  const thisAppContext = useContext(AppContext);
  const validCtx = ctx || thisAppContext;

  if (!validCtx.currentUser) {
    // TODO define specific error, or redirect automatically to login page ?
    toast.error(t("authentication.notLoggedIn"));
    throw "Unauthenticated";
  }

  return validCtx.currentUser
};

/**
 * Stateful component that ensures that the AppContext is properly initialized before displaying the content
 * @param props
 * @constructor
 */
export const WithInitAppContext = (props: { children: ElementsOrFn<UpdatableContextType<AppContextType>> }) => {
  const passport_url = new URLSearchParams(window.location.search).get('passport_url') || getApiUrl();
  const extensionId = useListenExtensionVersion();

  const { fetchCurrentUser, currentUser, isfetchCurrentUserPending } = useContextStore();

  const getCurrentUser = React.useCallback(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (extensionId && currentUser) {
      getExtension(extensionId).notifyLoginSuccess();
    }
  }, [extensionId, currentUser]);

  return !isfetchCurrentUserPending ? (
    <AppContext.StatefulProvider
      initContext={() => ({
        passport_url,
        currentUser,
        extensionId,
        applications: { loading: isfetchCurrentUserPending },
      })}
      // fetch the extension metadata after the context init
      onInit={(newContext) => {
        // trigger the async load of applications at the start of the app
        fetchApplications(newContext, getBrowserRemoteClient(newContext.passport_url));
        extensionId && newContext.updateCtx({ extensionId });
      }}
    >
      {props.children}
    </AppContext.StatefulProvider>
  ) : (
    <Loader />
  );
};
