import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';
import * as React from 'react';
import { Spacer } from '../interface';
import { ConfirmationButtonWrapper, ConfirmationDescription, Wrapper } from './styles';

type Props = {
  callback: (...args: any) => void;
  cancelText: string;
  closeMenu?: VoidFunction;
  confirmText: string;
  description?: string;
  type: buttonTypes;
  disableSubmit?: boolean;
  isLoading?: boolean;
};

const BasicConfirmation: React.FC<Props> = ({
  callback,
  cancelText,
  closeMenu,
  type,
  confirmText,
  description,
  disableSubmit,
  isLoading,
}: Props) => {
  const onClick = React.useCallback(
    (...args: any) => {
      callback(...args);
      if (closeMenu && !isLoading) {
        closeMenu();
      }
    },
    [callback, closeMenu, isLoading]
  );

  return (
    <Wrapper align="flex-end" direction="column" justify="center">
      {description && <ConfirmationDescription>{description}</ConfirmationDescription>}
      <Spacer top>
        <ConfirmationButtonWrapper>
          {closeMenu && <Button buttonType={buttonTypes.secondary} onClick={closeMenu} label={cancelText} />}
          <Button buttonType={type} onClick={onClick} disabled={disableSubmit} label={confirmText} />
        </ConfirmationButtonWrapper>
      </Spacer>
    </Wrapper>
  );
};

export default BasicConfirmation;
