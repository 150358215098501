const paletteTheme = {
  primary: {
    shade0: '#ECFFFF',
    shade1: '#DCFFFF',
    shade2: '#00ffff',
    shade3: '#00ffff',
    shade4: '#00E9E9',
    shade5: '#00d1d0',
    shade6: '#0bafae',
    shade7: '#1A366C',
    shade8: '#101F3C',
  },
  primaryLight: {
    shade1: '#D4F1F4',
  },
  secondary: {
    shade0: '#B3BBC6',
    shade1: '#B3BBC6',
    shade2: '#B3BBC6',
    shade3: '#B3BBC6',
    shade4: '#B3BBC6',
    shade7: '#1A366C',
    shade8: '#1A366C',
    shade9: '#101F3C',
    shade9_80: 'rgba(16,31,60,0.8)',
  },
  neutral: {
    shade0: '#ffffff',
    shade1: '#FBFCFE',
    shade2: '#D4E2E6',
    shade3: '#B3BBC6',
    shade4: '#AFB8C4',
    shade5: '#8892A0',
    shade6: '#59606B',
    shade7: '#484C60',
    shade8: '#2C353F',
    shade9: '#171717',
  },
  success: {
    shade1: '#E3FCEF',
    shade2: '#BCF7D7',
    shade3: '#8ADCB0',
    shade4: '#188A50',
    shade5: '#188A50',
    shade6: '#19663F',
    shade7: '#0A4D2B',
    shade8: '#033B1E',
    shade9: '#002B15',
  },
  warning: {
    shade1: '#FFFFD8',
    shade2: '#FFFAA5',
    shade3: '#FFF280',
    shade4: '#F6DE62',
    shade5: '#F6D636',
    shade6: '#F6C636',
    shade7: '#EEAE18',
    shade8: '#E09C16',
    shade9: '#C57600',
  },
  danger: {
    shade1: '#FEE4E3',
    shade2: '#F8C7C5',
    shade3: '#F78B87',
    shade4: '#B22A2A',
    shade5: '#B22A2A',
    shade6: '#A01818',
    shade7: '#7F0D0D',
    shade8: '#5F0505',
    shade9: '#440404',
  },
  blue: {
    shade2: '#e5f8fc',
  },
  green: {
    shade2: '#eff8f1',
  },
  purple: {
    shade2: '#f2edfd',
    shade5: '#7c4dff',
  },
  inrupt: {
    shade4: '#2989b1',
  },
  grey: {
    shade0: '#F0F0F0',
    shade1: '#E0E0E0',
    shade2: '#C7C7C7',
    shade3: '#AEAEAE',
  },
};
export default paletteTheme;
