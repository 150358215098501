// import { ExtensionDisclaimer } from 'components/extension-disclaimer';
import styled from 'config/theme/styled';
import React, { useContext } from 'react';
import { Landing } from '../../landing/Landing';
import { AppContext } from '../components/AppContext';
import SideNav from 'components/navigation';
import FeedbackForm from '../components/FeedbackForm';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  // enableExtensionCheck: boolean;
  // exact?: boolean;
  path: string;
};

const PrivateRoute = ({ children, path }: Props) => {
  const appContext = useContext(AppContext);
  const isUserLogedIn = appContext.currentUser?.id;

  return isUserLogedIn ? (
    <React.Fragment>
      {/* SideNav back in PrivateRoute - having it in App.tsx was showing it in passport when user is logged in */}
      <SideNav />
      <Content>
        <FeedbackForm />
        <Container>{children}</Container>
      </Content>
      {/* {enableExtensionCheck ? <ExtensionDisclaimer /> : null} */}
    </React.Fragment>
  ) : (
    <Landing />
  );
};

export default PrivateRoute;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (min-width: 768px) {
    height: 100%;
    margin: 0 0 0 250px;
    overflow-y: auto;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  text-align: left;
  width: 100%;
`;
