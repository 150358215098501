import { useRemoteClient } from '../app/components/AppContext';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Link, Route, useMatch, useResolvedPath } from 'react-router-dom';
import { Routes } from 'react-router';
import { CodeEditor, guessMode } from '../../utils/code-editor';
import { ColumnFlexDiv, Tab, Tabs } from './styles';
import { CollaboratorRole, getSpaceAlgo, ICollaborationSpace } from '@datavillage-me/api';
import { PromiseFnContainer } from '@datavillage-me/dv-common-ui';

export function AdminClientsRoute(props: any) {
  const path = useResolvedPath('').pathname;

  return (
    <Routes>
      <Route path={`${path}/:clientId/apps/:appId`}>
        <AdminClientApplication />
      </Route>
      <Route path={`${path}/:clientId`}>
        <AdminClientDetails />
      </Route>
      <Route path={`${path}`}>
        <AdminClientsList />
      </Route>
    </Routes>
  );
}

export const AdminClientsList = () => {
  const client = useRemoteClient();
  const url = useResolvedPath('');

  return (
    <ColumnFlexDiv>
      <h2>Clients</h2>
      <PromiseFnContainer promiseFn={() => client.getClientsServices().getClients()} deps={[]}>
        {(clients) => (
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>Name</td>
                <td>Creation date</td>
                <td>URL</td>
              </tr>
              {clients
                .sort((client1, client2) => (client1.name < client2.name ? 1 : -1))
                .map((client) => (
                  <tr key={client.id}>
                    <td>
                      <Link to={`${url}/${client.id}`}>{client.id}</Link>
                    </td>
                    <td>{client.name}</td>
                    <td>{client.creationDate && new Date(client.creationDate).toLocaleString()}</td>
                    <td>{client.url}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </PromiseFnContainer>
    </ColumnFlexDiv>
  );
};

export const AdminClientDetails = () => {
  const apiClient = useRemoteClient();
  const url = useResolvedPath('');
  const params = useMatch('/admin/clients/:clientId')?.params;
  if (!params || !params.clientId) {
    throw Error('No clientId found');
  }

  return (
    <ColumnFlexDiv>
      <h2>Client {params.clientId}</h2>
      <PromiseFnContainer
        promiseFn={() => apiClient.getClientsServices().getClient(params.clientId!)}
        deps={[params.clientId]}
      >
        {(client) => (
          <ColumnFlexDiv>
            <table>
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{client.id}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{client.name}</td>
                </tr>
                <tr>
                  <th>Created on</th>
                  <td>{client.creationDate && new Date(client.creationDate).toLocaleString()}</td>
                </tr>
                <tr>
                  <th>URL</th>
                  <td>{client.url}</td>
                </tr>
              </tbody>
            </table>
            <h3>Applications</h3>
            <PromiseFnContainer
              promiseFn={() => apiClient.getClientsServices().getCollaborationSpaces(client.id)}
              deps={[client.id]}
            >
              {(applications) => (
                <table>
                  <tbody>
                    <tr>
                      <td>App ID</td>
                      <td>App name</td>
                      <td>Creation date</td>
                      <td>URL</td>
                    </tr>
                    {applications.map((app) => (
                      <tr key={app.id}>
                        <td>
                          <Link to={`${url}/apps/${app.id}`}>{app.id}</Link>
                        </td>
                        <td>{app.name}</td>
                        <td>{/* app.creationDate && new Date(app.creationDate).toLocaleString() */}</td>
                        <td>{app.appSettings?.appUrl}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </PromiseFnContainer>
          </ColumnFlexDiv>
        )}
      </PromiseFnContainer>
    </ColumnFlexDiv>
  );
};

export const AdminClientApplication = () => {
  const apiClient = useRemoteClient();
  // const { params } = useResolvedPath<{ clientId: string; appId: string }>();
  const params = useMatch('/admin/clients/:clientId/apps/:appId')?.params;
  if (!params || !params.clientId || !params.appId) {
    throw Error('No clientId or appId found');
  }

  const [activeTab, setActiveTab] = useState(0);

  return (
    <ColumnFlexDiv>
      <h2>Application {params.appId}</h2>
      <PromiseFnContainer
        promiseFn={() => apiClient.getCollaborationSpacesServices().getCollaborationSpace(params.appId!)}
        deps={[params.clientId, params.appId]}
      >
        {(app) => (
          <ColumnFlexDiv>
            <div>
              <Tabs>
                <Tab className={`${activeTab == 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
                  Client Details
                </Tab>
                <Tab className={`${activeTab == 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
                  Application Settings
                </Tab>
                <Tab className={`${activeTab == 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>
                  Environment Settings
                </Tab>
                <Tab className={`${activeTab == 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}>
                  Consent Receipt
                </Tab>
              </Tabs>
            </div>

            {activeTab == 0 ? (
              <>
                <div>
                  <button
                    onClick={() =>
                      apiClient.getCollaborationSpacesServices().getOperatorServices(params.appId!).deploy()
                    }
                  >
                    DEPLOY
                  </button>
                  <button
                    onClick={() =>
                      apiClient.getCollaborationSpacesServices().getOperatorServices(params.appId!).delete()
                    }
                  >
                    DELETE
                  </button>
                </div>

                <table>
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <td>{app.id}</td>
                    </tr>
                    <tr>
                      <th>Client</th>
                      <td>
                        <Link to={`/admin/clients/${params.clientId}`}>{params.clientId}</Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <td>{app.name}</td>
                    </tr>
                    <tr>
                      <th>Callbacks</th>
                      <td>{app.appSettings?.allowedCallbacks.join(',')}</td>
                    </tr>
                  </tbody>
                </table>
                <h4>Status</h4>
                <PromiseFnContainer
                  promiseFn={() =>
                    apiClient.getCollaborationSpacesServices().getOperatorServices(params.appId!).getDeploymentLogs()
                  }
                  deps={[params.clientId, params.appId]}
                >
                  {(logs) =>
                    logs ? (
                      <>
                        {logs.map((l) => (
                          <div key={l.ts}>
                            {new Date(l.ts).toISOString()} [{l.status}] {l.message}
                          </div>
                        ))}
                      </>
                    ) : (
                      <i>No status</i>
                    )
                  }
                </PromiseFnContainer>
              </>
            ) : activeTab == 1 ? (
              <AppSettingsEditor app={app} />
            ) : activeTab == 2 ? (
              <AppEnvSettingsEditor app={app} />
            ) : activeTab == 3 ? (
              <PromiseFnContainer
                promiseFn={() =>
                  apiClient
                    .getConsentReceiptsServices()
                    .getCurrentConsentReceiptForApplication(params.clientId!, params.appId!)
                }
                deps={[params.clientId, params.appId]}
              >
                {(consentReceipt) =>
                  consentReceipt ? (
                    <>
                      <table>
                        <tbody>
                          <tr>
                            <th>ID</th>
                            <td>{consentReceipt['@id']}</td>
                          </tr>
                          {/* Already displayed above
                        <tr>
                          <th>Client</th>
                          <td>
                            <Link to={`/admin/clients/${consentReceipt.clientId}`}>{consentReceipt.clientId}</Link>
                          </td>
                        </tr>
                        <tr>
                          <th>Application</th>
                          <td>
                            <Link to={`/admin/clients/${consentReceipt.applicationId}`}>
                              {consentReceipt.applicationId}
                            </Link>
                          </td>
                        </tr>
                        */}
                          <tr>
                            <th>Creation date</th>
                            <td>{consentReceipt['gConsent:atTime']['time:inXSDDateTimeStamp']}</td>
                          </tr>
                          <tr>
                            <th>Purpose</th>
                            <td>{consentReceipt['gConsent:purpose']}</td>
                          </tr>
                          <tr>
                            <th>Processing</th>
                            <td>{consentReceipt['gConsent:forProcessing'].join(', ')}</td>
                          </tr>
                        </tbody>
                      </table>
                      <CodeEditor
                        code={JSON.stringify(consentReceipt, undefined, 4)}
                        language={guessMode(undefined, 'application/json')}
                        onCodeChange={(value) => {}}
                      />
                    </>
                  ) : null
                }
              </PromiseFnContainer>
            ) : null}
          </ColumnFlexDiv>
        )}
      </PromiseFnContainer>
    </ColumnFlexDiv>
  );
};

export const AppSettingsEditor = (props: { app: ICollaborationSpace }) => {
  const apiClient = useRemoteClient();

  const spaceAlgo = useMemo(() => getSpaceAlgo(props.app), [props.app]);

  const currentAppSettings = useMemo(
    () => JSON.stringify(spaceAlgo?.algoSettings, undefined, 4),
    [spaceAlgo?.algoSettings]
  );
  const [settingsString, setSettingsString] = useState(currentAppSettings);

  const isDirty = useMemo(() => settingsString == currentAppSettings, [settingsString, currentAppSettings]);

  const updateSettings = useCallback(
    (settings: any) => {
      spaceAlgo &&
        apiClient
          .getCollaborationSpacesServices()
          .updateCollaborator(props.app.id, spaceAlgo.clientId, CollaboratorRole.CodeProvider, {
            algoSettings: settings,
          });
    },
    [props.app.id, spaceAlgo?.clientId]
  );

  return (
    <>
      {isDirty ? <button onClick={() => updateSettings(JSON.parse(settingsString))}>Save Settings</button> : null}
      <CodeEditor
        code={settingsString}
        language={guessMode(undefined, 'application/json')}
        onCodeChange={setSettingsString}
      />
    </>
  );
};

export const AppEnvSettingsEditor = (props: { app: ICollaborationSpace }) => {
  const apiClient = useRemoteClient();

  const spaceAlgo = useMemo(() => getSpaceAlgo(props.app), [props.app]);

  const currentEnvSettings = useMemo(() => JSON.stringify(spaceAlgo?.settings, undefined, 4), [spaceAlgo?.settings]);
  const [settingsString, setSettingsString] = useState(currentEnvSettings);

  const isDirty = useMemo(() => settingsString == currentEnvSettings, [settingsString, currentEnvSettings]);

  const updateSettings = useCallback(
    (settings: any) => {
      spaceAlgo &&
        apiClient
          .getCollaborationSpacesServices()
          .updateCollaborator(props.app.id, spaceAlgo.clientId, CollaboratorRole.CodeProvider, { settings });
    },
    [props.app.id, spaceAlgo?.clientId]
  );

  return (
    <>
      {isDirty ? <button onClick={() => updateSettings(JSON.parse(settingsString))}>Save Env Settings</button> : null}
      <CodeEditor
        code={settingsString}
        language={guessMode(undefined, 'application/json')}
        onCodeChange={setSettingsString}
      />
    </>
  );
};
