import styled from 'styled-components';
import { Props } from '.';
import { Close } from '../../assets/icons';
import { palette } from '../../config/theme/themeFiles';

export const Badge = styled('div')<Props>`
  align-items: center;
  align-self: center;
  background-color: ${(props) => !props.onlyText && palette[`${props.color ? props.color : 'primary'}`].shade1};
  border-radius: 25px;
  color: ${(props) => palette[`${props.color ? props.color : 'primary'}`].shade4};
  display: inline-flex;
  font-size: ${(props) => (props.size === 'xsmall' ? '10px' : '13px')};
  font-weight: 600;
  letter-spacing: ${(props) => (props.isCapitalized ? 'normal' : '2px')};
  max-height: 100%;
  padding: ${(props) =>
    props.onlyText
      ? '0px'
      : props.size === 'large'
      ? '10px 20px'
      : props.size === 'small' || props.size === 'xsmall'
      ? '4px 14px'
      : '6px 23px'};
  text-align: center;
  text-transform: ${(props) => (props.isCapitalized ? 'lowercase' : 'uppercase')};

  &:first-letter {
    text-transform: ${(props) => props.isCapitalized && 'uppercase !important'};
  }
`;

export const CloseIcon = styled(Close)<Props>`
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  transition: transform 0.2s linear;
  border: solid 1px ${(props) => palette[`${props.color!}`].shade7};
  border-radius: 50%;
  padding: 1px;
  margin-right: ${(props) =>
    props.size === 'large' ? `-8px` : props.size === 'small' || props.size === 'xsmall' ? `-2px` : `-10px`};

  svg {
    fill: ${(props) => palette[`${props.color!}`].shade7};
    height: 1.1em;
    width: 1.1em;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

export const BadgeContent = styled.span<{ isCapitalized?: boolean }>`
  display: inline-block;
  &:first-letter {
    text-transform: ${(props) => props.isCapitalized && 'uppercase'};
  }
`;
