import NoServices from 'assets/illustrations/no_result.svg';
import { EmptyStateImage, EmptyStateWrapper } from 'components/emptyState/EmptyState';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageButton } from '../../../onboarding/Styles';

const ServicesEmptyState = () => {
  const { t } = useTranslation(['connections']);
  const navigate = useNavigate();

  const goToCatalog = () => {
    navigate('/connections/catalog');
  };

  return (
    <tr>
      <td colSpan={3}>
        <EmptyStateWrapper>
          <EmptyStateImage src={NoServices} />
          <p>{t('services.overview.noServicesFound')}</p>
          <PageButton onClick={goToCatalog}>{t('services.overview.goToCatalog')}</PageButton>
        </EmptyStateWrapper>
      </td>
    </tr>
  );
};

export default ServicesEmptyState;
