import { getRemoteClient } from '@datavillage-me/api';
import LoadingOverlay from 'components/overlay/LoadingOverlay';
import styled from 'config/theme/styled';
import paletteTheme from 'config/theme/themeFiles/palette';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from '../../utils';
import { AppContext } from '../app/components/AppContext';
import SelectProviderNew from './external-provider/SelectProviderNew';
// import FacebookLoginCta from './FacebookLoginCta';
type Props = { passport?: boolean; redirectUri?: string };
const LoginForm: React.FC<Props> = ({ passport, redirectUri }) => {
  const userNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const redirectUrl = useQuery().redirectUrl;

  const appContext = useContext(AppContext);
  const handleUserNamePasswordAuth = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);
      if (userNameRef.current?.value && passwordRef.current?.value)
        getRemoteClient(appContext.passport_url)
          .getPassport()
          .authenticate(userNameRef.current?.value, passwordRef.current?.value)
          .then((user) => appContext.updateCtx({ currentUser: user }));
      navigate('/');
    } catch (err) {
      toast.error('error');
    }
    setLoading(false);
  };

  return (
    <>
      {!passport && (
        <>
          <H3>{t('login.welcome')}</H3>
          <FormWrapper>
            <FormInput type="text" ref={userNameRef} placeholder="Username" disabled />
            <FormInput type="password" ref={passwordRef} placeholder="Password" disabled />
            <LoginButton type="submit" disabled={loading} onClick={handleUserNamePasswordAuth}>
              {t('login.login')} {t('login.comingSoon')}
            </LoginButton>
          </FormWrapper>
          <Divider>
            <span>{t('login.or')}</span>
          </Divider>
        </>
      )}
      {/*<FacebookLoginCta/>*/}

      <SelectProviderNew passport={passport} redirectUri={redirectUri || redirectUrl || window.location.href} />

      {loading && <LoadingOverlay />}
    </>
  );
};

export default LoginForm;

const LoginButton = styled.button`
  background: ${paletteTheme.grey.shade1};
  border-radius: 5px;
  border: none;
  color: ${paletteTheme.grey.shade3};
  font-size: 1em;
  font-weight: 400;
  padding: 8px 0;
  width: 100%;

  :hover {
    cursor: pointer;
  }
`;

const Divider = styled.p`
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 40px 0 40px;
  text-align: center;
  width: 100%;

  span {
    background: ${paletteTheme.neutral.shade1};
    padding: 0 20px;
  }
`;

// const ExternalLoginButton = styled(NavLink)`
//   background: transparent;
//   border-radius: 5px;
//   border: 2px solid ${paletteTheme.primary.shade5};
//   color: ${paletteTheme.neutral.shade9};
//   display: block;
//   text-align: center;
//   font-size: 1em;
//   padding: 8px 0;
//   width: 100%;
//   visibility: hidden;
//
//   :hover {
//     cursor: pointer;
//     color: ${paletteTheme.primary.shade5};
//   }
// `;

const FormWrapper = styled.form`
  width: 100%;
`;

// TODO: Create input component
const FormInput = styled.input`
  display: block;
  width: 100%;
  border-radius: 5px;
  margin: 16px 0;
  border: 1px solid ${paletteTheme.neutral.shade2};
  padding: 10px;
  background: ${paletteTheme.neutral.shade1};

  ::placeholder {
    color: ${paletteTheme.neutral.shade2};
    font-size: 1em;
    opacity: 1;
  }
`;

const H3 = styled.h3`
  text-align: center;
  margin-bottom: 40px;
`;
