import React from 'react';

type Props = { callbackTarget: string };

const LoginCallback: React.FC<Props> = (props: Props) => {
  const { callbackTarget } = props;
  if (!callbackTarget || callbackTarget == 'opener') {
    // Use window.postMessage to notify the original opener of this login window
    if (!window.opener) console.warn('Original caller unknown - login status cannot be sent back to caller');
    else {
      window.opener.postMessage('OK', window.location.origin);
    }
  } else if (callbackTarget.startsWith('extension:')) {
    if (chrome.runtime) {
      // Notify the extension specified in the target
      const extensionId = callbackTarget.substring('extension:'.length);
      chrome.runtime.sendMessage(extensionId, 'AUTH_OK');
    } else {
      console.warn('Chrome runtime not found in scope - cannot notify ' + callbackTarget);
    }
  } else {
    console.warn('Callback target unknown : ' + callbackTarget);
  }

  return <></>;
};

export default LoginCallback;
