import * as React from 'react';
import { useContext } from 'react';
import { AppContext, useRemoteClient } from '../app/components/AppContext';
import { Link, Route, useResolvedPath } from 'react-router-dom';
import { Routes } from 'react-router';
import { AdminUsersRoute } from './users';
import { AdminJobsRoute } from './jobs';
import { AdminClientsRoute } from './clients';
import { AdminConsentsRoute } from './consents';
import { ColumnFlexDiv } from './styles';
import ActivityMessage from '../activityLog/overview/ActivityMessage';
import { PromiseFnContainer } from '@datavillage-me/dv-common-ui';

export const Admin = () => {
  const url = useResolvedPath('');
  const path = url.pathname;

  return (
    <ColumnFlexDiv style={{ height: '100%' }}>
      <h2>Administration Console</h2>
      <ul>
        <li>
          <Link to={`${url}`}>Dashboard</Link>
        </li>
        <li>
          <Link to={`${url}/users`}>Users</Link>
        </li>
        <li>
          <Link to={`${url}/jobs`}>Jobs</Link>
        </li>
        <li>
          <Link to={`${url}/consents`}>Consents</Link>
        </li>
        <li>
          <Link to={`${url}/clients`}>Clients</Link>
        </li>
        <li>
          <Link to={`${url}/activity`}>Activity Log</Link>
        </li>
      </ul>
      <Routes>
        <Route path={`${path}/users`}>
          <AdminUsersRoute />
        </Route>
        <Route path={`${path}/jobs`}>
          <AdminJobsRoute />
        </Route>
        <Route path={`${path}/consents`}>
          <AdminConsentsRoute />
        </Route>
        <Route path={`${path}/clients`}>
          <AdminClientsRoute />
        </Route>
        <Route path={`${path}/activity`}>
          <AdminActivityLog />
        </Route>
        <Route path={`${path}`}>
          <AdminDashboard />
        </Route>
      </Routes>
    </ColumnFlexDiv>
  );
};

export const AdminDashboard = () => {
  const context = useContext(AppContext);

  return (
    <div style={{ position: 'relative', top: '0px', bottom: '0px' }}>
      <div>Server URL : {context.passport_url}</div>
    </div>
  );
};

export const AdminActivityLog = () => {
  const client = useRemoteClient();

  return (
    <div style={{ position: 'relative', top: '0px', bottom: '0px' }}>
      <h2>Activity Log</h2>
      <PromiseFnContainer promiseFn={() => client.getActivityLog().getActivityLog({})} deps={[]}>
        {(activities) => (
          <table>
            <tbody>
              <tr>
                <td>Timestamp</td>
                <td>Level</td>
                <td>Type</td>
                <td>Message</td>
                <td>User</td>
                <td>Consent</td>
              </tr>
              {activities.map((activity) => (
                <tr key={activity.timestamp}>
                  <td>{new Date(activity.timestamp).toLocaleString()}</td>
                  <td>{activity.logLevel}</td>
                  <td>{activity.type}</td>
                  <td>
                    <ActivityMessage activity={activity} />
                  </td>
                  <td>{activity.userId}</td>
                  <td>{activity.consentId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </PromiseFnContainer>
    </div>
  );
};
