import { RevokeConsent } from 'assets/icons';
import IconButton from 'components/button/IconButton';
import { Confirmation } from 'components/confirmationModal';
import { TableRow } from 'components/table/Table.styles';
import Tooltip from 'components/tooltip';
import { useExtension } from 'hooks/useExtension';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useResolvedPath } from 'react-router-dom';
import { logoFactory } from 'utils/images';
import useServicesStore from '../hooks/useServicesStore';
import { Service } from '../types';
import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';
import { Connected, Logo, LogoHelper, NameAndCategoryContainer, StateContainer } from './Services.styled';

type Props = {
  servicesList: Service[] | null;
};

const ServicesTable = ({ servicesList }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['connections', 'services']);
  const path = useResolvedPath('').pathname; // from migration https://stackoverflow.com/a/74394703
  const { disconnectService, isDisconnectServicePending } = useServicesStore();
  const hasNoExtension = useExtension() === undefined;

  const handleGoToDetail = (serviceName: string, event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`${path}/${serviceName}`);
  };

  const handleConnectService = React.useCallback((serviceUrl: string, event: React.MouseEvent) => {
    event.stopPropagation();
    window.open(serviceUrl + '?startPassport=true', '_blank');
  }, []);

  const handleDisconnectService = React.useCallback(
    (serviceId: string | undefined, event: React.MouseEvent) => {
      event.stopPropagation();
      if (!serviceId) {
        // TODO: what to do if no serviceId?
        throw Error('No serviceId found');
      }
      disconnectService(serviceId, () => window.location.reload());
    },
    [disconnectService]
  );

  return (
    <React.Fragment>
      {servicesList &&
        servicesList.length > 0 &&
        servicesList.map((service: Service) => {
          return (
            <TableRow key={service.id} onClick={(e) => handleGoToDetail(service.name, e)}>
              <td>
                <NameAndCategoryContainer>
                  <LogoHelper>
                    <Tooltip content={service.name}>
                      <Logo src={service.appSettings.logoUrl || logoFactory(service.name)} alt={service.name} />
                    </Tooltip>
                  </LogoHelper>
                </NameAndCategoryContainer>
              </td>

              <td>
                <p>{service.purpose}</p>
              </td>

              {/*
              <td>
                {service?.userData?.activatedProviders && service.userData.activatedProviders.length > 0 ? (
                  <DataSources>
                    {service.userData.activatedProviders.map((provider) => (
                      <Tooltip key={provider} content={provider}>
                        <Logo src={logoFactory(provider)} alt={provider} />
                      </Tooltip>
                    ))}
                  </DataSources>
                ) : (
                  <p>{'-'}</p>
                )}
              </td> */}

              <td>
                <StateContainer>
                  {service.userData?.applicationActivated ? (
                    <Connected>{t('table.connected')}</Connected>
                  ) : (
                    <Tooltip
                      content={hasNoExtension ? t('services:actions.disabled.connect') : t('services.actions.connect')}
                    >
                      <Button
                        buttonType={buttonTypes.primary}
                        disabled={hasNoExtension}
                        onClick={(e) => handleConnectService(service.appSettings.appUrl, e)}
                      >
                        {t('table.clickToConnect')}
                      </Button>
                    </Tooltip>
                  )}
                </StateContainer>
              </td>
              <td>
                {service.userData?.applicationActivated && (
                  <Confirmation
                    key={service.id}
                    variant="delete"
                    orientation="bottom-right"
                    callback={(e) => handleDisconnectService(service.userData?.activeConsentId, e)}
                    customConfirmText={t('services:actions.confirmDisconnect')}
                    customDescription={t('services:actions.confirmDescription')}
                  >
                    <IconButton
                      tooltip={t('services:actions.disconnect')}
                      tooltipPosition="left"
                      isLoading={isDisconnectServicePending === service.userData?.activeConsentId}
                      isDisabled={isDisconnectServicePending === service.userData?.activeConsentId}
                    >
                      <RevokeConsent />
                    </IconButton>
                  </Confirmation>
                )}
              </td>
            </TableRow>
          );
        })}
    </React.Fragment>
  );
};

export default ServicesTable;
