import React from 'react';
import { DataSourceMetadata } from '@datavillage-me/api';
import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';
import { useTranslation } from 'react-i18next';

import { useRemoteClient } from '../../modules/app/components/AppContext';
import styled from '../../config/theme/styled';

type Props = {
  dataSource: DataSourceMetadata & { recommended?: boolean };
  onCancel: () => void;
};

export const ActivateDataSourceView: React.FC<Props> = ({ dataSource, onCancel }) => {
  const remoteClient = useRemoteClient();
  const { t } = useTranslation();

  const onActivate = () => {
    remoteClient
      .getPassport()
      .getDatasourceActivationUrl(window.location.origin + `/provider/${dataSource.id}/success`, dataSource.id)
      .then((url) => {
        window.open(url);
      });
  };

  return (
    <ActivateDataSourceViewContainer>
      <p className={'mapping'}>{t(`passport.selectSources.activateWarning`, { DATA_SOURCE_NAME: dataSource.label })}</p>
      <Button
        {...{
          buttonType: buttonTypes.success,
          onClick: onActivate,
          label: t(`general.activate`),
        }}
      />
      <Button
        {...{
          buttonType: buttonTypes.danger,
          onClick: onCancel,
          label: t('general.cancel'),
        }}
      />
    </ActivateDataSourceViewContainer>
  );
};

const ActivateDataSourceViewContainer = styled.div`
  text-align: center;

  button {
    margin-bottom: 10px;
  }
`;
